import React from "react"

const ChevronRight = ({className, fill}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      viewBox="0 0 551.13 551.13"
      width="7pt"
      fill={fill ? fill : 'white'}
    >
      <path d="m361.679 275.565-223.896 223.897v51.668l275.565-275.565-275.565-275.565v51.668z" />
    </svg>
  )
}

export default ChevronRight
