import React from "react"
import ChevronRight from '../icons/chevronRight';

const Footer = () => (
  <footer className="bg-footer">
  <div className="hide-sm">
    <div className="container center-between flex-wrap w-50 ml-100-lg mb-20">
      <div className="d-flex column">
        <h5 className="text-dark">About us</h5>
        {/* <a href='/about' className="text-dark mb-10">Company Profile</a> */}
        {/* <a href='/about#clients' className="text-dark my-10">Clients</a> */}
        <a className="text-dark my-10">News</a>
        <a href='/services' className="text-dark my-10">Service</a>
      </div>
      <div>
        <h5 className="text-dark">Consumer</h5>
        <a href='/products' className="text-dark">Products</a>
        <p className="text-dark">Experience</p>
      </div>
      <div>
        <h5 className="text-dark">Contact us</h5>
        <p className="text-dark">Mail: vmks@rishaba.in</p>
        <p className="text-dark">Call: +91 8754444356</p>
      </div>
    </div>
    <hr className="text-faded m-0"/>
    <div className="center-between w-100 mt-10 bg-footer mt-20 pb-50">
      <p className="text-dark ml-100-lg">Copyright @ 2020 Rishaba.in | All rights reserved.</p>
      <div className="d-flex row mr-50">
        <p className="text-dark mr-10">| Privacy Policy</p>
        <p className="text-dark mr-10">| Terms of use |</p>
        <p className="text-dark mr-10">Career |</p>
      </div>
    </div>
  </div>
  <div className="hide-lg bg-black">
    <div className="container">
      <p className="mb-20 font-size-15 text-light pt-20">About us</p>
      {/* <div className="center-between w-100">
        <a href='/about' className="mb-10 font-size-10 text-light">Company Profile</a>
        <ChevronRight />
      </div> */}
      {/* <hr className="text-faded mb-10"/>
      <div className="center-between w-100">
        <a href="/about#clients" className="my-10 font-size-10 text-light">Clients</a>
        <ChevronRight />
      </div> */}
      <hr className="text-faded mb-10"/>
      <div className="center-between w-100">
        <a href='/services' className="my-10 font-size-10 text-light">Service</a>
        <ChevronRight />
      </div>
      <hr className="text-faded mb-10"/>
      <div className="center-between w-100">
        <p className="mb-10 font-size-10 text-light">News</p>
        <ChevronRight />
      </div>
      <hr className="text-faded mb-10"/>
    </div>
    <div className="container">
      <p className="mb-20 font-size-15 text-light pt-20">Consumer</p>
      <div className="center-between w-100">
        <a href='/products' className="my-10 font-size-10 text-light">Products</a>
        <ChevronRight />
      </div>
      <hr className="text-faded mb-10"/>
    </div>
    <div className="container pb-50">
      <p className="mb-20 font-size-15 text-light pt-20">Contact us</p>
      <div className="center-between w-100">
        <p className="mb-10 font-size-10 text-light">Mail: vmks@rishaba.in</p>
        <ChevronRight />
      </div>
      <hr className="text-faded mb-10"/>
      <div className="center-between w-100">
        <p className="mb-10 font-size-10 text-light">Call: +91 8754444356</p>
        <ChevronRight />
      </div>
      <hr className="text-faded m-0" />
    </div>
    <hr className="text-faded m-0" />
    <p className="text-dark ml-100-lg pt-50 font-size-10 text-align-center">Copyright @ 2020 Rishaba.in | All rights reserved.</p>
    <div className="d-flex justify-content-center row">
      <p className="text-dark mr-10 font-size-10">| Privacy Policy</p>
      <p className="text-dark mr-10 font-size-10">| Terms of use |</p>
      <p className="text-dark mr-10 font-size-10">Career |</p>
    </div>
  </div>
  </footer>
)

export default Footer