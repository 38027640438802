import React, { useState } from "react"
import ChevronRight from "../icons/chevronRight"

const Menu = () => {
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = (event) => {
    setShowMenu(!showMenu)
  }
  return (
    <div onClick={toggleMenu} onKeyDown={toggleMenu} role="button" tabIndex="0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -53 384 384"
        width="15pt"
        className="hide-lg mr-20 cursor-pointer"
      >
        <path d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
        <path d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
        <path d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
      </svg>
      {showMenu && (
        <div style={{position: 'fixed', backgroundColor: 'white', width: '100vw', height: '100vh', top: 0, left: 0, zIndex: 10}}>
          <ChevronRight />
          <div className="d-flex column text-align-center-sm mt-30">
            <a className="text-primary my-20 menu-font" href="/">Home</a>
            {/* <a className="text-primary my-20 menu-font" href="/about">About us</a> */}
            <a className="text-primary my-20 menu-font" href="/products">Products</a>
            <a className="text-primary my-20 menu-font" href="/services">Services</a>
            <a className="text-primary my-20 menu-font" href="/contact">Contact</a>
          </div>
        </div>
      )}
    </div>
  )
}

export default Menu
