import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import ProductsDropdown from "../products/productsDropdown"
import Menu from "../ui/menu"
import PhoneIcon from "../icons/phoneIcon"

const Header = ({ currentPath, siteTitle }) => {
  const isActive = (path) => {
    if (currentPath !== path) {
      return "text-faded"
    }
    return ""
  }
  return (
    <header className="bottom-shadow-sm">
      <div className="container py-30 px-20">
        <div className="row center-between">
          <div className="center row align-items-center p-0 m-0">
            <Menu />
            <h3 className="mr-30 m-0 text-primary">
              <Link
                to="/"
                className="text-primary font-bold"
              >
                {siteTitle}
              </Link>
            </h3>
            <a
              className={`mr-30 m-0 font-size-14 hide-sm letter-spacing ${isActive(
                "/"
              )}`}
              href="/"
            >
              Home
            </a>
            {/* <a
              className={`mr-30 m-0 font-size-14 hide-sm letter-spacing ${isActive(
                "/about/"
              )}`}
              href="/about"
            >
              About us
            </a> */}
            <div className="products center hide-sm">
              <a
                className={`mr-30 m-0 font-size-14 letter-spacing ${isActive(
                  "/products/"
                )}`}
                href="/products"
              >
                Products
              </a>
              <ProductsDropdown />
            </div>
            <a
              className={`mr-30 m-0 font-size-14 hide-sm letter-spacing ${isActive(
                "/services/"
              )}`}
              href="/services"
            >
              Services
            </a>
            <a
              className={`mr-30 m-0 font-size-14 hide-sm letter-spacing ${isActive(
                "/contact"
              )}`}
              href="/contact"
            >
              Contact
            </a>
          </div>
          <div className="d-flex hide-lg">
            <PhoneIcon />
            <a
              href="tel:+918754444356"
              className="ml-20 font-size-14 text-secondary m-0"
            >
              +91 8754444356
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
