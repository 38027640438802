import React from 'react'

const ProductsDropdown = () => {
  return (
    <>
      <div className="products-dropdown" style={{width: 50, height: 40, position: 'absolute', transform: 'rotate(45deg)', top: 60}}></div>
      <div className="products-dropdown d-flex justify-content-center row" style={{position: 'absolute', top: '60px', padding: '20px', zIndex: 3}}>
        <div className="d-flex column justify-content-start mr-20">
          <a className="my-20 text-primary" href='/products/manual-chopper'>Manual Chopper</a>
          <a className="my-20 text-primary" href='/products/insulated-jug'>Insulated Jug</a>
          {/* <a className="my-20 text-primary" href='/products/vaccum-flasks-and-water-bottles'>Vaccum Flasks &<br/>Water Bottles</a> */}
        </div>
        <div className="vl mr-20"></div>
        <div className="d-flex column justify-content-start mr-20">
          <a className="my-20 text-primary" href='/products/food-processor'>Food Processor</a>
          <a className="my-20 text-primary" href='/products/multi-grater-and-slicer'>Multi Grater</a>
          <a className="my-20 text-primary" href='/products/yogurt-container'>Yogurt Container</a>
        </div>
        <div className="vl mr-20"></div>
        <div className="d-flex column justify-content-start">
          <a className="my-20 text-primary" href='/products/premium-chopping-boards'>Premium Chopping Boards</a>
          <a className="my-20 text-primary" href='/products/storage-containers'>Storage Containers</a>
        </div>
      </div>
    </>
  )
}

export default ProductsDropdown;