module.exports = {
  PRODUCTS: {
    'manual-chopper': {
      name: 'Manual Chopper',
      slug: 'manual-chopper',
      animate: true,
      display_description: 'This compact yet powerful chopper allows you to precisely chop fruits and vegetables in a matter of seconds.',
      main_description: 'Manual stackable chopper is a handy, portable solution that takes all the hard work out of food preparation , this compact yet powerful product allows you to precisely chop and prepare ingredients in a matter of seconds.',
      colors: ['#485363', '#CDE11D', '#FF4C59'],
      display_image: '/products/manual-chopper/display.png',
      set_image: '/products/manual-chopper/manual_chopper_set.png',
      main_image: '',
      features: [
        {
          image: '/products/manual-chopper/manual_chopper_feature_1.png',
          name: 'Squircle profile',
          description: 'Provides unique aesthetic and enhances chopping.',
        },
        {
          image: '/products/manual-chopper/manual_chopper_feature_2.png',
          name: 'Press fit lid',
          description: 'Silicone gasket to seal the jar.',
        },
        {
          image: '/products/manual-chopper/manual_chopper_feature_3.png',
          name: 'Non skid base',
          description: 'For effortless use and satisfying results.',
        },
        {
          image: '/products/manual-chopper/manual_chopper_feature_4.png',
          name: 'Stackable containers',
          description: 'A set of containers with silicone lids to ensure sealed storage of your ingredients. Available in 2 sizes: 600ml & 900ml.',
        },
        {
          image: '/products/manual-chopper/manual_chopper_feature_5.png',
          name: 'Available in 2 sizes',
          description: '900ml and 600ml',
        },
        {
          image: '/products/manual-chopper/manual_chopper_feature_6.png',
          name: 'Container lid',
          description: 'Container lids to easily and quickly store the chopped veggies.',
        },
        {
          image: '/products/manual-chopper/manual_chopper_feature_7.png',
          name: 'Attachments',
          description: 'Whisk for beating eggs and chopping blade to slice up vegetables.',
        }
      ]
    },
    'multi-grater-and-slicer': {
      name: 'Multi Grater and Slicer',
      slug: 'multi-grater-and-slicer',
      animate: true,
      display_description: 'The multi grater can be used for slicing, grating, mincing, chopping your favourite fruits and vegetables. Includes a set of 4 long lasting stainless steel blades specialized for each function.',
      main_description: 'The multi grater can be used for slicing, grating, mincing, chopping your favourite fruits and vegetables. Includes a set of 4 long lasting stainless steel blades specialized for each function.',
      colors: ['#E88F00', '#DF3F4A', '#BCCD0E'],
      display_image: '/products/multi-grater/display.png',
      set_image: '/products/multi-grater/multi_grater_set.png',
      main_image: '',
      features: [
        {
          image: '/products/multi-grater/multi_grater_feature_1.png',
          name: 'Blades',
          description: '4 different interchangeable stainless steel blades : grater, fine shedder, coarse shedder and julienne insert.',
        },
        {
          image: '/products/multi-grater/multi_grater_feature_2.png',
          name: 'Finger safety',
          description: 'Comes with finger safety  guard for hassle-free slicing and grating experience.',
        },
        {
          image: '/products/multi-grater/multi_grater_feature_3.png',
          name: 'Blade storage',
          description: 'The blades slot into the multi grater for compact storage.',
        }
      ]
    },
    'food-processor': {
      name: 'Food Processor',
      slug: 'food-processor',
      animate: false,
      display_description: 'This food processor comes with specialized attachments that allow you to break down and process various ingredients in a matter of seconds.',
      main_description: 'This food processor comes with specialized attachments that allow you to break down and process various ingredients in a matter of seconds.',
      colors: ['#2E2E2E'],
      display_image: '/products/food-processor/display.png',
      set_image: '/products/food-processor/food_processor_set.png',
      main_image: '/products/food-processor/food_processor_main.png',
      features: [
        {
          image: '/products/food-processor/food_processor_feature_1.png',
          name: 'Juicer',
          description: 'The juicer attachment is suitable for any juicy fruits, comes with a citrus squeezer which extracts juice efficiently and quickly.',
        },
        {
          image: '/products/food-processor/food_processor_feature_2.png',
          name: 'Whisk',
          description: 'The whisk attachment assists in beating eggs, preparing whipped cream and other baking ingredients.',
        },
        {
          image: '/products/food-processor/food_processor_feature_3.png',
          name: 'Slicer blades',
          description: 'Cuts, slices, chops ingredients instantly. The small and large pushers enable users to feed the fruits/vegetables accordingly.',
        },
        {
          image: '/products/food-processor/food_processor_feature_4.png',
          name: 'Atta kneader',
          description: 'A key ingredient in Indian cuisine, the atta kneader effortlessly kneads dough in a very short time.',
        },
        {
          image: '/products/food-processor/food_processor_feature_5.png',
          name: 'Slicer blade',
          description: 'Standard blades for processing a variety of ingredients.',
        },
      ]
    },
    'insulated-jug': {
      name: 'Insulated Jug',
      slug: 'insulated-jug',
      animate: true,
      display_description: 'The Vacuum Insulated Jug is built to keep your beverage hot or cool for 12 hours. It comes with the option of 2 lids that makes pouring completely hassle- free.',
      main_description: 'The Vacuum Insulated Jug is built to keep your beverage hot or cool for 12 hours. It comes with the option of 2 lids that makes pouring completely hassle- free.',
      colors: ['#135686', '#515359', '#D2D2D2'],
      display_image: '/products/insulated-jug/display.png',
      set_image: '/products/insulated-jug/insulated_jug_set.png',
      main_image: '',
      features: [
        {
          image: '/products/insulated-jug/insulated_jug_feature_1.png',
          name: 'Convenient handle',
          description: 'This leak-proof insulated jug comes with an easy grip handle , which lets you hold it at suitable angle to make pouring your favorite beverage even easier.',
        },
        {
          image: '/products/insulated-jug/insulated_jug_feature_2.png',
          name: 'Glass Inner',
          description: 'World class standard glass inner built in India ensures long lasting temperature retention.',
        },
        {
          image: '/products/insulated-jug/insulated_jug_feature_3.png',
          name: '1 Ltr capacity',
          description: 'Available in 1 ltr capacity and 3 different colour variants',
        }
      ]
    },
    // 'vaccum-flasks-and-water-bottles': {
    //   name: 'Vaccum Flasks & Water Bottles',
    //   slug: 'vaccum-flasks-and-water-bottles',
    //   animate: false,
    //   display_description: 'Range of insulated water bottles and flasks that retain heat or cold for 12 hours , built for excellent vacuum retention and comes in a variety of appealing colours and finishes.',
    //   main_description: 'Range of insulated water bottles and flasks that retain heat or cold for 12 hours , built for excellent vacuum retention and comes in a variety of appealing colours and finishes.',
    //   colors: ['#2DB393', '#F37061', '#FFA23C', '#458B93', '#723F4A', '#668398', '#414957', '#4D9C8D'],
    //   display_image: '/products/flasks/display.png',
    //   set_image: '/products/flasks/flasks_set.png',
    //   main_image: '/products/flasks/flasks_main.png',
    //   features: [
    //     {
    //       image: '/products/flasks/flasks_feature_1.png',
    //       name: 'Leisure',
    //       description: 'A flask for outdoor use, like the beach or a camping trip. Larger volume for sharing refreshing beverages among friends. Loop handle for convenience.',
    //     },
    //     {
    //       image: '/products/flasks/flasks_feature_2.png',
    //       name: 'Urban',
    //       description: 'Everyday use flask that fits in your bag during your commute. Available in a colour of your choice as per your taste. Suitable for kids and adults alike.',
    //     },
    //     {
    //       image: '/products/flasks/flasks_feature_3.png',
    //       name: 'Home & Office',
    //       description: 'A larger flask for social or workplace gatherings. The twist and pour cap allows for hassle free serving.',
    //     },
    //   ]
    // },
    'premium-chopping-boards': {
      name: 'Premium Chopping Boards',
      slug: 'premium-chopping-boards',
      animate: false,
      coming_soon: true,
      display_description: 'High quality chopping board made of 100% premium food grade and durable plastic board. Comes in a variety of textured finishes.',
      main_description: 'Manual stackable chopper is a handy , portable solution that takes all the hard work out of food preparation , this compact yet powerful product allows you to precisely chop and prepare ingredients in a matter of seconds.',
      display_image: '/products/premium-chopping-boards/display.png',
      set_image: '/products/premium-chopping-boards/display.png',
    },
    'storage-containers': {
      name: 'Storage Containers',
      slug: 'storage-containers',
      animate: false,
      coming_soon: true,
      display_description: 'A set of containers with silicone lids to ensure sealed storage of your ingredients. Available in 2 sizes: 600ml & 900ml.',
      main_description: 'Manual stackable chopper is a handy , portable solution that takes all the hard work out of food preparation , this compact yet powerful product allows you to precisely chop and prepare ingredients in a matter of seconds.',
      display_image: '/products/storage-containers/display.png',
      set_image: '/products/storage-containers/display.png',
    },
    'yogurt-container': {
      name: 'Yogurt Containers',
      slug: 'yogurt-container',
      animate: false,
      coming_soon: true,
      display_description: 'Made from high-quality food grade plastic , this yogurt container is manufactured to be portable for daily use. Air tight lid keeps the contents fresh for longer. Compact & durable build withstands accidental drops and knocks.',
      main_description: 'Manual stackable chopper is a handy , portable solution that takes all the hard work out of food preparation , this compact yet powerful product allows you to precisely chop and prepare ingredients in a matter of seconds.',
      display_image: '/products/yogurt-container/display.png',
      set_image: '/products/yogurt-container/display.png',
    },
  }
}